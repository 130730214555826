$ = jQuery;
var isPaused = !0;

function topNumbers() {
    $(".numbers span.arrow").on("click", function (e) {
        e.preventDefault(), $(this).parents(".numbers").toggleClass("open", 500)
    }), $(document).mouseup(function (e) {
        var t = $(".numbers");
        t.is(e.target) || 0 !== t.has(e.target).length || t.removeClass("open")
    })
}

function stickyHeader() {
    var e, t = 0, a = 5;
    $("#masthead").outerHeight();
    $(window).scroll(function (t) {
        e = !0
    }), setInterval(function () {
        e && (!function () {
            var e = $(this).scrollTop();
            if (Math.abs(t - e) <= a) return;
            e > t && window.pageYOffset > 100 ? $("#masthead, .mobile-contacts").removeClass("sticky").addClass("sticky-up") : e + $(window).height() < $(document).height() && $("#masthead, .mobile-contacts").removeClass("sticky-up").addClass("sticky");
            window.pageYOffset < 100 && $("#masthead").removeClass("sticky");
            t = e
        }(), e = !1)
    }, 250)
}

function smoothScroll() {
    $("a[href^='#']").on("click", function (e) {
        if ("" !== this.hash) if (e.preventDefault(), "#top" == this.hash) $("html, body").animate({scrollTop: 0}, 800, function () {
        }); else {
            var t = this.hash;
            $("html, body").animate({scrollTop: $(t).offset().top}, 800, function () {
            })
        }
    })
}

function mobileMenu() {
    $(".menu-toggle").on("click", function (e) {
        e.preventDefault(), $("body").toggleClass("modal-open"), $(".main-navigation").toggleClass("active"), $(".menu-bg").fadeToggle()
    })
}

function textSliderBlockSlider() {
    $("#row-list_slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !1,
        draggable: !1,
        swipe: !1,
        rows: 0
    }), $("#row-list_nav").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: "#row-list_slider",
        dots: !1,
        arrows: !0,
        centerMode: !0,
        focusOnSelect: !0,
        rows: 0
    }), $("#row-list_doc li[data-slide=1]").addClass("active"), $(".nav_svg svg circle[data-slide=1]").addClass("active"), $("#row-list_doc li[data-slide]").click(function (e) {
        e.preventDefault();
        var t = $(this).data("slide"), a = $(this).data("slide");
        $(".nav_svg svg circle").removeClass("active"), $(".nav_svg svg circle[data-slide=" + t + "]").addClass("active"), $(".nav_svg svg").css("transform", "rotate(" + -a + "deg)"), $("#row-list_slider").slick("slickGoTo", t - 1), $("#row-list_nav").slick("slickGoTo", t - 1)
    }), $("#row-list_slider, #row-list_nav").on("beforeChange", function (e, t, a, s) {
        let o = s + 1;
        $(".nav_svg svg circle").removeClass("active"), $(".nav_svg svg circle[data-slide=" + o + "]").addClass("active"), $(".nav_svg svg").css("transform", "rotate(" + -o + "deg)"), $("#row-list_doc li").removeClass("active"), $("#row-list_doc li[data-slide=" + o + "]").addClass("active")
    })
}

function textSliderBlockSliderCol() {
    $("#col-list_slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !1,
        draggable: !1,
        swipe: !1
    }), $("#col-list_nav").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: "#col-list_slider",
        dots: !1,
        arrows: !0,
        centerMode: !0,
        focusOnSelect: !0
    }), $("#col-list_doc li[data-slide=1]").addClass("active"), $(".nav_svg svg circle[data-slide=1]").addClass("active"), $("#col-list_doc li[data-slide]").click(function (e) {
        e.preventDefault();
        var t = $(this).data("slide"), a = $(this).data("slide");
        $(".nav_svg svg circle").removeClass("active"), $(".nav_svg svg circle[data-slide=" + t + "]").addClass("active"), $(".nav_svg svg").css("transform", "rotate(" + -a + "deg)"), $("#col-list_slider").slick("slickGoTo", t - 1), $("#col-list_nav").slick("slickGoTo", t - 1)
    }), $("#col-list_slider, #col-list_nav").on("beforeChange", function (e, t, a, s) {
        let o = s + 1;
        $(".nav_svg svg circle").removeClass("active"), $(".nav_svg svg circle[data-slide=" + o + "]").addClass("active"), $(".nav_svg svg").css("transform", "rotate(" + -o + "deg)"), $("#col-list_doc li").removeClass("active"), $("#col-list_doc li[data-slide=" + o + "]").addClass("active")
    })
}

function contactTabs() {
    $("ul.country li").on("click", function () {
        var e = $(this).attr("id");
        $("ul.country li").removeClass("active"), $(this).addClass("active"), $(".tab_content").removeClass("show"), $(".tab_content[id=" + e + "]").addClass("show"), $(".map_tab").removeClass("show"), $(".map_tab[id=" + e + "]").addClass("show")
    })
}

function tnxTabs() {
    $("ul.country_list").on("click", "span.arrow, li:first-child", function () {
        $("ul.country_list").toggleClass("open"), $(this).closest("ul.country_list").children("li:not(:first-child)").toggle()
    });
    var e = $("ul.country_list").children("li:not(:first-child)");
    $("ul.country_list").on("click", "li:not(:first-child)", function () {
        e.removeClass("select"), $(this).addClass("select");
        var t = $(this).attr("id");
        $("ul.country_list").children("li:first-child").html($(this).html()), $("ul.country_list").children("li:first-child").attr("id", t), e.toggle(), $("ul.country_list").removeClass("open")
    }), $("ul.country_list li").on("click", function () {
        var e = $(this).attr("id");
        $("ul.country_list li").removeClass("active"), $(this).addClass("active"), $(".phones_tab").removeClass("show"), $(".phones_tab[id=" + e + "]").addClass("show")
    })
}

function maps() {
    function e(e) {
        var t = e.find(".marker"), a = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            disableDefaultUI: !0,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        }, s = new google.maps.Map(e[0], a);
        return s.markers = [], t.each(function () {
            !function (e, t) {
                var a = new google.maps.LatLng(e.attr("data-lat"), e.attr("data-lng")),
                    s = new google.maps.Marker({position: a, map: t});
                if (t.markers.push(s), e.html()) {
                    var o = new google.maps.InfoWindow({content: e.html()});
                    google.maps.event.addListener(s, "click", function () {
                        o.open(t, s)
                    })
                }
            }($(this), s)
        }), function (e) {
            var t = new google.maps.LatLngBounds;
            $.each(e.markers, function (e, a) {
                var s = new google.maps.LatLng(a.position.lat(), a.position.lng());
                t.extend(s)
            }), 1 == e.markers.length ? (e.setCenter(t.getCenter()), e.setZoom(16)) : e.fitBounds(t)
        }(s), s
    }

    $(document).ready(function () {
        $(".ru, .ua, .ro, .bs").each(function () {
            e($(this))
        })
    })
}

function popups() {
    $("#modal_1").on("shown.bs.modal", function () {
        isPaused = true
    }), $("#modal_1").on("hidden.bs.modal", function (e) {
        isPaused = false
    }), $('a[data-toggle="modal"]').on("click", function () {
        isPaused = true
    })
}

function openPopUpOne() {
    mousEctive = false;
    var e = $("#modal_2");
    $("html").on("mouseleave", function () {
        $("#happyNewYearModal .close").click()

        mousEctive || isPaused || $(e).modal({
            fadeDuration: 500,
            fadeDelay: .5,
            blockerClass: "modal_show"
        }), mousEctive = true
    })
}

function minutesOpen() {
    setTimeout(function () {
        var e = $("#modal_2");
        $("body").hasClass("modal-open") || (isPaused || mousEctive || $(e).modal({
            fadeDuration: 500,
            fadeDelay: .5,
            blockerClass: "modal_show"
        }), isPaused = !0)
    }, 60000)
}

const happyNewYearModal = () => {
    setTimeout(function () {
        let m = $("#happyNewYearModal");
        $('body').hasClass('modal-open') || (isPaused || mousEctive || $(m).modal({
            fadeDuration: 500,
            fadeDelay: .5,
            blockerClass: 'modal_show'
        }), isPaused = false)
    }, 2000)
}
happyNewYearModal()

function resetForm() {
    $("#modal_1, #modal_2").on("hidden.bs.modal", function () {
        $("form div.error").remove()
    })
}

$(function () {
    $(window).load(function () {
        $(".preloader").fadeOut(500), isPaused = !1
    }), smoothScroll(), stickyHeader(), mobileMenu(), topNumbers(), textSliderBlockSlider(), textSliderBlockSliderCol(), popups(), contactTabs(), tnxTabs(), maps(), openPopUpOne(), minutesOpen(), happyNewYearModal(), resetForm()
});
